@import './_color-variables.scss';
@import './_mixins.scss';

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .image {
    width: 50%;
  }

  .text {
    @include heading-4;

    color: $title;
  }
}
