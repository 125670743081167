@import '../_color-variables.scss';
@import '../_mixins.scss';
@import '../_variables.scss';

.footer-landing {
  border-top: 0.5px solid rgba(255, 255, 255, 40%);
  max-width: 1178px;
  margin: 0 auto;
  padding: 80px 24px 70px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include tablet {
    flex-direction: column;
    padding: 60px 24px;
  }
}

.box-info {
  width: 45%;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: $title;

  img {
    width: 155px;
  }

  @include tablet {
    width: 100%;
    margin-bottom: 40px;

    img {
      width: 125px;
    }
  }
}

.description {
  max-width: 420px;
  margin: 24px 0;
  font-weight: 400;
}

.name,
.description {
  opacity: 0.6;
}

.menus-link {
  width: 50%;
  display: flex;

  @include tablet {
    width: 100%;
  }
}

.menus {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  width: 48%;
  color: $title;
}

// code landing v2
.banner-footer {
  background: url('/images/banner-footer.png') no-repeat center;
  background-size: 100% auto;
  border-radius: 20px;
  width: 100%;
  position: absolute;
  bottom: 100%;
  z-index: 2;
  .title-banner {
    @include bold-h3;
    color: white;
    max-width: 559px;
    letter-spacing: -1px;
    @media (max-width: 992px) {
      @include bold-h4;
    }
  }
  .btn-start-free {
    width: 225px;
    height: 65px;
    border-radius: 80px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $brand-main;
    @include bold-h6;
    transition: all 0.25s linear;

    &:hover {
      background: $brand-gradient;
      border: 0;
      color: $title;
    }
  }
  @media (max-width: 992px) {
    background: url('/images/banner-footer-mobile.png') no-repeat center;
    background-size: 100% auto;
  }
}

.field-text {
  position: relative;
  .dot {
    $dot-circle: 4px;
    position: absolute;
    display: block;
    top: calc(50% - $dot-circle / 2);
    left: 5px;
    width: $dot-circle;
    height: $dot-circle;
    border-radius: calc($dot-circle / 2);
    background: $brand-main;
    z-index: 1;
  }
  .menu-item {
    position: relative;
    @include medium-h7;
    color: $bg1;
    display: block;
    opacity: 1 !important;
    z-index: 2;
    margin-bottom: 24px;
    .text {
      display: block;
      padding-left: 0;
      transition: all 0.25s linear;
      background: #ffffff;
    }
    &:hover > .text {
      transform: translateX(20px);
      color: $brand-main;
    }
    @media (max-width) {
      @include medium-h8;
    }
  }
}
.copy-right {
  @include regular-h8;
  color: $bg1;
  @media (max-width: 992px) {
    @include regular-h9;
  }
}

.link-social {
  color: $brand-main;
  border-bottom: 2px solid transparent;
  transition: all 0.25s linear;
  margin-left: 50px;

  @include medium-h7;

  @media (max-width: 992px) {
    margin-left: 20px;

    @include medium-h9;
  }

  &:hover {
    border-bottom: 2px solid $brand-main;
  }
}

.menu-mobile {
  .text-link-menu {
    color: #000224;
    opacity: 1 !important;
    @include medium-h8;
  }
}
