@import '../_color-variables.scss';
@import '../_mixins.scss';
@import '../_variables.scss';

.header-landing {
  position: fixed;
  padding: 30px 0;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  z-index: 99;

  @include tablet {
    padding: 24px 0;

    &.mobile {
      height: 100vh;
      background-image: url('/images/bg-menu-mobile.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .btn-primary {
    background: linear-gradient(225deg, #226cff 14.89%, #1084ff 85.85%);
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    padding: 12px 24px;
    color: $title;
    font-family: 'Anderson Grotesk Bold', sans-serif;
    border: transparent;

    &:hover {
      cursor: pointer;
      background: #226cff;
    }
  }

  .menus {
    display: flex;
    align-items: center;
    height: 100%;

    .menu {
      font-family: 'Anderson Grotesk Bold', sans-serif;
      font-weight: 700;
      font-size: 18px;
      color: $title;
      display: block;
      margin-right: 40px;

      &:hover {
        color: $link;
        text-decoration: underline;
      }
    }
  }

  .menus-mobile {
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Anderson Grotesk', sans-serif;
    justify-content: center;

    a {
      display: block;
      color: $title;
      margin-bottom: 60px;
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      margin-right: 0;
    }
  }

  &.fixed {
    background-color: $bg1;
  }

  .content {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1178px;
    padding: 0 24px;
    box-sizing: border-box;

    @include tablet {
      align-items: center;

      img {
        width: 125px;
      }
    }
  }
}

.desktop-ui {
  display: block;
  @include tablet {
    display: none;
  }
}

.mobile-ui {
  display: none;
  @include tablet {
    display: block;
  }
}

// code landing v2

.is-sticky {
  background: white !important;
}

.logo-icon {
  height: 100%;
  width: auto;
}
.menu-item {
  @include medium-h8;
  display: flex;
  align-items: center;
  color: $bg1;
  transition: all 0.25s linear;
  padding-top: 12px;
  padding-bottom: 12px;
  .arrow-down {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    margin-left: 8px;
    margin-bottom: 4px;
  }
  &:hover {
    cursor: pointer;
    color: $brand-main;
  }
}

.btn-login {
  width: 120px;
  height: 46px;
  border-radius: 23px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $brand-gradient;
  @include bold-h8;

  &:hover {
    background: $brand-main;
  }
}

.table-menu-mobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background: white;
  overflow: auto;
  padding-top: 82px;
  transition: all 0.25s linear;
  z-index: 10;
  &.show-menu {
    height: 100vh;
  }
  .btn-login {
    width: 100%;
  }
  .menu-item {
    width: 100%;
    padding-top: 11px;
    padding-bottom: 11px;
  }
}
